import styled from "styled-components";
import { 
  BP, 
  Colors, 
  Font, 
  Rem, 
} from "../../../commons/Theme";
import { StyledBigText, StyledParagraph } from "../../../commons/Typography";
import { motion } from "framer-motion";
import { Button } from "@3beehivetech/components";

const BigText = motion(StyledBigText);
const Paragraph = motion(StyledParagraph);
const ButtonMotion = motion(Button);

const getTextColor = (isDarkBackground) =>
  isDarkBackground ? Colors.white : Colors.black;

const getBackgroundColor = (isDarkBackground) =>
  isDarkBackground ? Colors.black : Colors.white;

export const StyledGridSectionContainer = styled(motion.div)`
  width: 100%;

  padding: ${Rem(50)} ${Rem(20)};
  background-color: ${({ isDarkBackground }) =>
    getBackgroundColor(isDarkBackground)};
  transition: all 0.65s ease-out;
  position: relative;

  @media (${BP.ipad}) {
    padding: ${Rem(240)} ${Rem(148)} 0 ${Rem(148)};
  }
`;

export const StyledGridSectionHeaderContainer = styled(motion.div)`
  margin-bottom: ${Rem(24)};
  color: ${({ isDarkBackground }) => getTextColor(isDarkBackground)};
  position: relative;
  @media (${BP.ipad}) {
    text-align: center;
    margin-bottom: ${Rem(76)};
  }

  & > :nth-child(1) {
    margin-bottom: ${Rem(22)};
  }
`;

export const StyledGridSectionTitle = styled(BigText)`
  font-family: 'Vollkorn';
  font-weight: 700;
  font-size: ${Rem(32)};
  line-height: ${Rem(44)};
  z-index: 1;
  position: inherit;
  padding: 0;

  @media (${BP.ipad}) {
    font-size: ${Rem(40)};
    line-height: ${Rem(56)};

    max-width: 60%;
    margin: 0 auto;
  }
`;

export const StyledWrapperAudio = styled(motion.div)`
  display: flex;
  justify-content: center;
`;

export const StyledGridSectionDescription = styled(Paragraph)`
  font-family: ${Font.sans};
  font-weight: 700;
  font-size: ${Rem(16)};
  line-height: ${Rem(20)};
  letter-spacing: 3px;
  z-index: 1;
  position: inherit;
  @media (${BP.tablet}) {
    font-size: ${Rem(24)};
    line-height: ${Rem(30)};

    max-width: 70%;
    margin: 0 auto;
  }
`;

export const StyledGridSectionItemsWrapper = styled.div`
  position: relative;
`;

export const StyledWrapperDots = styled(motion.div)`
  position: absolute;
  top: -15%;
  left: ${({ dots }) => dots === "left" ? "-10%" : "65%"};
  width: 150px;
  @media (${BP.tablet}) {
    top: 0%;
    left: ${({ dots }) => dots === "left" ? "0%" : "65%"};
    width: 300px;
  }
  div {
    z-index: 0;
  }
`;

const columnGap = 128;
export const StyledGridSectionItemsContainer = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;

  @media (${BP.ipad}) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    row-gap: ${Rem(64)};
    column-gap: ${Rem(columnGap)};
    align-items: center;
    position: relative;
    & > * {
      position: relative;
    }

    /* & > :nth-child(odd)::before {
      content: "";

      position: absolute;
      top: -10px;
      right: -${Rem(columnGap / 2)};

      width: ${Rem(60)};
      height: 2px;

      background-color: ${Colors.yellow};
    }

    & > :nth-child(even)::before {
      content: "";

      position: absolute;
      top: -10px;
      left: -${Rem(columnGap / 2)};

      width: ${Rem(60)};
      height: 2px;

      background-color: ${Colors.yellow};
    } */
  }
`;

export const StyledGridSectionFooterContainer = styled(motion.div)`
  margin-top: ${Rem(80)};
  color: ${({ isDarkBackground }) => getTextColor(isDarkBackground)};
  @media (${BP.ipad}) {
    text-align: center;
    margin-top: ${Rem(200)};
  }
`;

export const StyledVolumeContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  margin-bottom: 0;

  margin: ${Rem(24)} 0 ${Rem(32)} 0;

  color: ${Colors.yellow};

  gap: ${Rem(12)};

  cursor: pointer;

  span {
    padding: 4px;
    margin-left: 2px;
    border-radius: 50%;
    border: 2px solid ${Colors.yellow};
    width: 1.8rem;
  }

  p {
    font-size: ${Rem(12)};
    line-height: ${Rem(20)};
    letter-spacing: 3px;
  }

  svg {
    height: 100%;
  }
`;
